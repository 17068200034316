import { AxiosRequestConfig, AxiosResponse } from "axios";
import { APIServicePaths } from ".";
import { api, API_HOST } from "./api.config";

export interface APIDataBase {
  id: string;
  _id?: string;
  __v?: string;
  createdAt?: string;
  updatedAt?: string;
  published_at?: string;
  provider?: string;
}

export interface APIDataMedia extends APIDataBase {
  ext: string;
  hash: string;
  mime: string;
  name: string;
  formats?: {
    thumbnail: Omit<APIDataMedia, "formats">;
    large: Omit<APIDataMedia, "formats">;
    medium: Omit<APIDataMedia, "formats">;
    small: Omit<APIDataMedia, "formats">;
  };
  related: string[];
  url: string;
  size: number;
  height: number;
  width: number;
}

export interface APIDataUseCase extends APIDataBase {
  title: string;
  subtitle: string;
  description: string;
  previewPhoto: APIDataMedia;
  provider: string;
  industryFocus: string;
  function: string;
  customerUsed: string;
}

/**
 * Service to accessing data that will add a header with a JWT token automatically
 * when requesting authorized resources from API.
 */
class APIService {
  /**
   * Makes a GET request call.
   *
   * @param {string} url an any path
   * @param {AxiosRequestConfig} config an axios config
   *
   * @returns {Promise<AxiosResponse>}
   */
  static get(url: string, config: AxiosRequestConfig = {}): Promise<AxiosResponse> {
    return api.get(url, config);
  }

  /**
   * Makes a POST request call.
   *
   * @param {string} url an any path
   * @param {any} data an any data as object
   * @param {AxiosRequestConfig} config an axios config
   *
   * @returns {Promise<AxiosResponse>}
   */
  static post(
    url: string,
    data: any = {},
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse> {
    return api.post(url, data, config);
  }

  /**
   * Makes a PUT request call.
   *
   * @param {string} url an any path
   * @param {any} data an any data
   * @param {AxiosRequestConfig} config an axios config
   *
   * @returns {Promise<AxiosResponse>}
   */
  static put(
    url: string,
    data: any = {},
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse> {
    return api.put(url, data, config);
  }

  /**
   * Makes a DEL request call.
   *
   * @param {string} url an any path
   * @param {AxiosRequestConfig} config an axios config
   *
   * @returns {Promise<AxiosResponse>}
   */
  static delete(url: string, config: AxiosRequestConfig = {}): Promise<AxiosResponse> {
    return api.delete(url, config);
  }

  /**
   * Upload a file
   */
  static postUpload(file: File): Promise<AxiosResponse<APIDataMedia[]>> {
    const formData = new FormData();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    formData.append("files", file);

    return this.post(APIServicePaths.Upload, formData, config);
  }

  /**
   * --------------------------
   * Just as it is, combine API URL with given image URL on server
   * --------------------------
   */
  static getImageURL = (imgUrl: string | undefined | null): string =>
    !!imgUrl?.length ? `${API_HOST}${imgUrl}` : "";
}

export default APIService;
