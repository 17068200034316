import React, { CSSProperties } from "react";
import styled from "styled-components";

export enum TextType {
  Caption = "caption",
  Small = "sm",
  Middle = "md",
  Large = "lg",
  Large48 = "lg48",
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
}

export enum TextColor {
  Primary = "primary",
  BananaDark = "bdark",
  Secondary = "secondary",
  Red = "red",
  Black = "black",
  Grey = "grey",
  GreyDark = "greyDark",
  GreyLight = "greyLight",
  Light8 = "light8",
  White = "white",
  Link = "link",
}

export enum TextFont {
  Roboto = "Roboto",
  Montserrat = "Montserrat",
  MontserratAlternates = "Montserrat Alternates",
}

interface TextDivStyledProps {
  accentColor: string;
  color: TextColor;
  font?: TextFont;
  type?: TextType;
  noWrap?: boolean;
  isHardTextWrap?: boolean;
  isInvert?: boolean;
  isCenter?: boolean;
  isRight?: boolean;
  isExtraBold?: boolean;
  isBold?: boolean;
  isPointer?: boolean;
  isInline?: boolean;
  isUpperCase?: boolean;
  isLink?: boolean;
  width?: CSSProperties["width"];
}

const TextDivStyled = styled.div<TextDivStyledProps>`
  margin: 0;
  padding: 0;
  height: fit-content;
  width: ${({ width }) => width ?? "fit-content"};
  transition: all 0.35s ease-in-out;
  user-select: none;

  text-decoration: none;
  &::hover {
    text-decoration: ${({ isLink }) => (isLink ? "underline" : "none")};
  }

  display: ${({ isInline }) => (isInline ? "inline" : "block")};
  color: ${({ color, accentColor }) => {
    const _colors: Record<TextColor, CSSProperties["color"]> = {
      primary: accentColor,
      bdark: "#c69f06",
      red: "#E53900",
      secondary: "rgba(0, 0, 0, 0.1)",
      black: "#000000",
      grey: "rgba(0, 0, 0, 0.5)",
      greyDark: "rgba(0, 0, 0, 0.8)",
      greyLight: "rgba(0, 0, 0, 0.2)",
      light8: "rgba(255, 255, 255, 0.8)",
      white: "#FFFFFF",
      link: "#4A91DB",
    };
    return _colors[color];
  }};

  text-align: ${({ isCenter, isRight }) =>
    isCenter ? "center" : isRight ? "right" : "left"};
  word-break: ${({ noWrap, isHardTextWrap }) =>
    noWrap ? "nowrap" : isHardTextWrap ? "break-all" : "wrap"};
  white-space: ${({ noWrap }) => (noWrap ? "nowrap" : "wrap")};
  flex-wrap: ${({ noWrap }) => (noWrap ? "nowrap" : "wrap")};
  font-family: ${({ font }) => `"${font}", sans-serif`};
  font-weight: ${({ isExtraBold, isBold }) =>
    isExtraBold ? 700 : isBold ? 500 : "inherit"};
  font-size: ${({ type }) => {
    const _typedSizes: Record<TextType, CSSProperties["fontSize"]> = {
      [TextType.Caption]: "14px",
      [TextType.Small]: "16px",
      [TextType.Middle]: "20px",
      [TextType.Large]: "32px",
      [TextType.Large48]: "48px",
      [TextType.H1]: "80px",
      [TextType.H2]: "60px",
      [TextType.H3]: "50px",
      [TextType.H4]: "40px",
    };

    return _typedSizes[type ?? TextType.Small];
  }};
  line-height: 130%;

  cursor: ${({ isPointer }) => (isPointer ? "pointer" : "normal")};
  text-transform: ${({ isUpperCase }) => (isUpperCase ? "uppercase" : "none")};
`;

export interface TextProps
  extends Omit<TextDivStyledProps, "color" | "accentColor" | "schema"> {
  className?: string;
  children?: React.ReactNode;
  text?: string | null;
  color?: TextColor;
  onClick?: React.MouseEventHandler<HTMLDivElement>;

  style?: CSSProperties;
}

const Text: React.FC<TextProps> = ({
  className = "",
  children,
  text = null,
  type,
  color = TextColor.Grey,
  onClick = null,
  font = TextFont.Roboto,
  style = {},
  isLink,
  isBold,
  isExtraBold,
  isCenter,
  isRight,
  isPointer,
  isInline,
  isInvert,
  isUpperCase,
  isHardTextWrap,
  noWrap,
  width,
}) => {
  return (
    <TextDivStyled
      accentColor={"#FFCC00"}
      onClick={onClick ? onClick : () => {}}
      className={className ?? ""}
      type={type}
      isLink={isLink}
      font={font}
      color={color ?? TextColor.Grey}
      noWrap={noWrap}
      isHardTextWrap={isHardTextWrap}
      isRight={isRight}
      isCenter={isCenter}
      isExtraBold={isExtraBold}
      isBold={isBold}
      isInline={isInline}
      isPointer={isPointer || !!onClick}
      isUpperCase={isUpperCase}
      isInvert={isInvert}
      style={style ? { ...style } : {}}
      width={width}>
      {text ?? children}
    </TextDivStyled>
  );
};

export default Text;
