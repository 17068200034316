import axios from "axios";

export const API_HOST = process.env.REACT_APP_API_HOST as string;
export const API_RESPONSE_TIMEOUT = parseInt(process.env.REACT_APP_API_TIMEOUT as string);

export const API_LIMIT_UPLOAD_FILES = parseInt(
  process.env.REACT_APP_API_LIMIT_UPLOAD_FILES as string
);
export const API_LIMIT_UPLOAD_MEDIA_TYPES =
  process.env.REACT_APP_API_LIMIT_UPLOAD_MEDIA_TYPES?.split(" ") as string[];
export const API_LIMIT_UPLOAD_SIZE = parseInt(
  process.env.REACT_APP_API_LIMIT_UPLOAD_SIZE as string
);

export enum APIServicePaths {
  GetCompanyPublic = "companies/by-public-code",
  GetUseCaseApprove = "use-cases/by-approve-code",

  UseCase = "use-cases",
  UseCaseApprove = "use-cases/approve",
  UseCaseReject = "use-cases/reject",

  Upload = "upload",
}
export const api = axios.create({
  baseURL: API_HOST,
  timeout: API_RESPONSE_TIMEOUT,
});
