/**
 * Converting a 'x'-bytes to correct formatted string
 */
export const formatBytes = (x: number | string) => {
  const FORMAT_UNITS = ["byte", "Kb", "Mb", "Gb", "Tb"];

  let l = 0;
  let n = parseInt(typeof x === "number" ? x.toString() : x, 10) || 0;

  while (n >= 1024) {
    n /= 1024;
    l += 1;
  }

  return `${n.toFixed(n < 10 && l > 0 ? 2 : 0)} ${FORMAT_UNITS[l]}`;
};
