import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import ReactModal from "react-modal";

import MotionDiv from "../../common/MotionDiv";

import AppSuspenseLoader from "../../AppSuspenseLoader";
import { AppRoutingPaths } from "../../App";
import APIService, { APIDataUseCase, APIServicePaths } from "../../services/APIService";

import Block from "../../common/Block";
import Text, { TextColor, TextType } from "../../common/Text";
import Button, { ButtonType } from "../../common/Button";
import Spacer from "../../common/Spacer";
import UseCaseCreation from "./UseCaseCreation";
import UseCaseApproving from "./UseCaseApproving";

interface UseCasesPageProps {}

enum UCCreateStepsType {
  Loading = "loading",

  Creation = "creation",

  Approving = "approving",
}

const UseCasesPage: React.VFC<UseCasesPageProps> = ({}) => {
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const [pageType, setPageType] = useState<UCCreateStepsType>(UCCreateStepsType.Loading);
  const [companyData, setCompanyData] = useState<{ name: string } | undefined>(undefined);
  const [useCaseData, setUseCaseData] = useState<APIDataUseCase | undefined>(undefined);
  const verificationCode = useMemo(() => query.get("code"), [query]);

  const _pageTypeComponents = useMemo<Record<UCCreateStepsType, React.VFC<any> | null>>(
    () => ({
      [UCCreateStepsType.Loading]: AppSuspenseLoader,

      [UCCreateStepsType.Creation]: UseCaseCreation,

      [UCCreateStepsType.Approving]: UseCaseApproving,
    }),
    []
  );

  const PageTypedComponent = useMemo(
    () => _pageTypeComponents[pageType],
    [pageType, _pageTypeComponents]
  );

  const [isSubmitted, setIsSubmitted] = useState<{
    status: boolean;
    type: "Rejected" | "Approved";
  }>({ status: false, type: "Approved" });

  const onSubmitUC = useCallback((ucType?: "Rejected" | "Approved") => {
    setIsSubmitted({ status: true, type: ucType as "Rejected" | "Approved" });
  }, []);

  useEffect(() => {
    const _proceed = async () => {
      const codeLength = verificationCode?.length;
      if (
        !codeLength ||
        (!codeLength && codeLength !== 32) ||
        (!codeLength && codeLength !== 64)
      ) {
        navigate(AppRoutingPaths.Error);
        return;
      }

      let pageTo = UCCreateStepsType.Creation;

      const approveQuery = query.get("t");
      if (approveQuery === "approve") {
        pageTo = UCCreateStepsType.Approving;

        const uc = await APIService.get(
          `${APIServicePaths.GetUseCaseApprove}/${verificationCode}`
        ).then(
          (resp) => resp.data,
          (err) => {
            navigate(
              {
                pathname: AppRoutingPaths.Error,
                search:
                  "err=Can't get data about Use Case. Check the link you copied or try again later...",
              },
              { replace: true }
            );
          }
        );

        if (!uc) {
          return;
        } else {
          setUseCaseData(uc);
        }
      } else {
        const c = await APIService.get(
          `${APIServicePaths.GetCompanyPublic}/${verificationCode}`
        ).then(
          (resp) => resp.data,
          (err) => {
            navigate(
              {
                pathname: AppRoutingPaths.Error,
                search:
                  "err=Can't get data about your company. Check the link you copied or try again later...",
              },
              { replace: true }
            );
          }
        );

        if (!c) {
          return;
        } else {
          setCompanyData(c);
        }
      }

      setTimeout(() => {
        setPageType(pageTo);
      }, 700);
    };
    _proceed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MotionDiv>
      <ReactModal
        ariaHideApp={false}
        isOpen={isSubmitted.status}
        className={`app-modal auto-center static`}
        overlayClassName={`app-overlay`}>
        <Block isThin width={"320px"} className='f-col'>
          <Text isExtraBold color={TextColor.GreyDark} type={TextType.Middle}>
            Thank you
          </Text>

          <Spacer top='4px' />

          <Text color={TextColor.GreyDark}>
            {isSubmitted.type?.length
              ? `This Use Case was successfully ${isSubmitted.type}.`
              : "Your Use Case has been sent to the server. After it’s validated, it’ll appear in the system. Now you can close this browser window."}
          </Text>

          <Spacer top='24px' />

          <Button
            type={ButtonType.Primary}
            onClick={(e) => {
              setIsSubmitted({ ...isSubmitted, status: false });
            }}
            style={{
              margin: "0 auto",
              padding: "12px 32px",
            }}
            className='d-flex on-hover'>
            <Text
              isExtraBold
              color={TextColor.White}
              style={{
                textShadow: "0px 1px 2px rgba(0, 0, 0, 0.35)",
              }}
              className='welcome-block-text'>
              Close
            </Text>
          </Button>
        </Block>
      </ReactModal>

      <AnimatePresence exitBeforeEnter>
        {PageTypedComponent && (
          <PageTypedComponent
            onSubmit={onSubmitUC}
            companyData={companyData}
            useCaseData={useCaseData}
            verificationCode={verificationCode}
          />
        )}
      </AnimatePresence>
    </MotionDiv>
  );
};

export default UseCasesPage;
