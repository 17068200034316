import React, { CSSProperties } from "react";
import styled from "styled-components";

import Icon, { IconProps } from "../Icon";

interface ButtonIcoDivProps {
  isOutline: boolean;
  isOutlineClrAsBg: boolean;
  disabled: boolean;
  size: CSSProperties["width"];
  backgroundColor:
    | CSSProperties["backgroundColor"]
    | {
        active: CSSProperties["backgroundColor"];
        hover: CSSProperties["backgroundColor"];
        default: CSSProperties["backgroundColor"];
        disabled: CSSProperties["backgroundColor"];
      };
  borderRadius: CSSProperties["borderRadius"];
}
const ButtonIcoDiv = styled.div<ButtonIcoDivProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.35s ease-in-out;

  border-radius: ${({ borderRadius }) => borderRadius ?? "50%"};
  outline: ${({ isOutline, isOutlineClrAsBg, backgroundColor, disabled }) =>
    isOutline
      ? `1px solid ${
          isOutlineClrAsBg
            ? typeof backgroundColor === "string"
              ? backgroundColor
              : disabled
              ? backgroundColor?.disabled
              : backgroundColor?.default
            : "#fff"
        }`
      : "none"};
  background: ${({ isOutline, backgroundColor, disabled }) =>
    isOutline
      ? ""
      : typeof backgroundColor === "string"
      ? backgroundColor
      : disabled
      ? backgroundColor?.disabled
      : backgroundColor?.default};
  height: ${({ size }) => size ?? "64px"};
  width: ${({ size }) => size ?? "64px"};
  min-width: ${({ size }) => size ?? "64px"};

  &:hover {
    background: ${({ backgroundColor }) =>
      typeof backgroundColor === "string" ? backgroundColor : backgroundColor?.hover};
  }
  &:active {
    background: ${({ backgroundColor }) =>
      typeof backgroundColor === "string" ? backgroundColor : backgroundColor?.active};
  }
`;

interface ButtonIcoProps extends Partial<Omit<ButtonIcoDivProps, "filter">> {
  className?: string;
  icon?: IconProps | null;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined | null;
  disabled?: boolean;
  style?: CSSProperties;
}

const ButtonIco: React.VFC<ButtonIcoProps> = ({
  className = "",
  icon,
  onClick = () => {},
  size,
  backgroundColor = {
    active: "rgba(0, 0, 0, 0.35)",
    hover: "rgba(0, 0, 0, 0.1)",
    default: "rgba(0, 0, 0, 0.05)",
    disabled: "rgba(0, 0, 0, 0.1)",
  },
  isOutline = false,
  isOutlineClrAsBg = false,
  borderRadius = "50%",
  disabled = false,
  style = {},
}) => {
  return (
    <ButtonIcoDiv
      size={size}
      borderRadius={borderRadius}
      backgroundColor={backgroundColor}
      disabled={disabled}
      isOutline={isOutline}
      isOutlineClrAsBg={isOutlineClrAsBg}
      onClick={(e) => {
        if (onClick && !disabled) {
          onClick(e);
        }
      }}
      style={style}
      className={`${className ?? ""}`}>
      {icon && (
        <Icon size='16px' opacity={disabled ? "15%" : "65%"} isIgnoreSchema {...icon} />
      )}
    </ButtonIcoDiv>
  );
};

export default ButtonIco;
