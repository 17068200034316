import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import Block from "../../common/Block";
import MotionDiv from "../../common/MotionDiv";
import Spacer from "../../common/Spacer";
import Text, { TextColor, TextFont, TextType } from "../../common/Text";

interface ErrorPageProps {}

const ErrorPage: React.VFC<ErrorPageProps> = ({}) => {
  const [query] = useSearchParams();
  const errorMessage = useMemo(() => query.get("err"), [query]);

  return (
    <MotionDiv className='d-flex f-ccenter w-100'>
      <Block isThin isShadow={true} className='f-col' width={"420px"}>
        <Text
          className='w-100'
          isCenter
          font={TextFont.Montserrat}
          color={TextColor.Red}
          type={TextType.Large}>
          Error 404
        </Text>
        <Spacer top='12px' />
        <Text color={TextColor.GreyDark} type={TextType.Middle} isCenter>
          {!errorMessage?.length
            ? "You do not have access to this page. Please contact the administrator or check the copied link."
            : errorMessage}
        </Text>
      </Block>
    </MotionDiv>
  );
};

export default ErrorPage;
