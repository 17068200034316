import React, { CSSProperties, useMemo } from "react";
import styled from "styled-components";

interface SpacerStyledProps {
  margin: CSSProperties["margin"];
}
const SpacerStyled = styled.div<SpacerStyledProps>`
  margin: ${({ margin }) => margin};
`;

interface SpacerProps {
  x?: string;
  y?: string;
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
  style?: CSSProperties;
  onClick?: () => void;
  className?: string;
}

const Spacer: React.VFC<SpacerProps> = ({
  x,
  y,
  left,
  right,
  top,
  bottom,
  style,
  onClick,
  className = "",
}) => {
  const _margins = useMemo(
    () => ({
      mt: top ? top : y ? y : 0,
      mb: bottom ? bottom : y ? y : 0,
      ml: left ? left : x ? x : 0,
      mr: right ? right : x ? x : 0,
    }),
    [bottom, left, right, top, x, y]
  );

  return (
    <SpacerStyled
      margin={`${_margins.mt} ${_margins.mr} ${_margins.mb} ${_margins.ml}`}
      className={`spacer ${className ?? ""}`}
      style={style ?? {}}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          onClick();
        }
      }}
    />
  );
};

export default Spacer;
