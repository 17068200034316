import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

interface ImageStyledProps {}

const ImageStyled = styled.img<ImageStyledProps>`
  transition: all 0.35s ease-in-out;
`;

interface LazyImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  placeholderSrc?: string;
  src?: string;
  errImg?: string;
}

export const LazyImage: React.VFC<LazyImageProps> = ({
  placeholderSrc,
  src,
  errImg,
  ...props
}) => {
  const [imgSrc, setImgSrc] = useState(placeholderSrc || src);

  const onLoad = useCallback(() => {
    setImgSrc(src);
  }, [src]);

  const onError = useCallback(() => {
    setImgSrc(errImg || placeholderSrc);
  }, [errImg, placeholderSrc]);

  useEffect(() => {
    const img = new Image();
    img.src = src as string;
    img.addEventListener("load", onLoad);
    img.addEventListener("error", onError);

    return () => {
      img.removeEventListener("load", onLoad);
      img.removeEventListener("error", onError);
    };
  }, [src, onLoad, onError]);

  return <ImageStyled {...props} alt={imgSrc} src={imgSrc} />;
};

export default LazyImage;
