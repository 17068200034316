import React, { useMemo } from "react";
import ContentLoader from "react-content-loader";

interface DataLoaderProps {
  align?: "y" | "x";
  count?: number;
  height?: number;
  width?: number;
  gap?: number;
  rounded?: number | string;
  rectProps?: React.SVGProps<SVGRectElement>;
}

const DataLoader: React.VFC<DataLoaderProps> = ({
  align = "y",
  count = 3,
  height = 61.25,
  width = 0,
  gap = 0,
  rounded = "10px",
  rectProps = {},
}) => {
  const _count = useMemo(() => count ?? 3, [count]);
  const _contentArr = useMemo(() => new Array(_count).fill(0), [_count]);
  const _contentHeight = useMemo(
    () =>
      align === "y" ? (height ? _count * height + (_count - 1) * gap : "auto") : height,
    [_count, align, gap, height]
  );
  const _contentWidth = useMemo(
    () =>
      align === "x" ? (width ? _count * width + (_count - 1) * gap : "auto") : width,
    [_count, align, gap, width]
  );

  return (
    <ContentLoader width={_contentWidth} height={_contentHeight} className='w-100'>
      {_contentArr.map((_, idx) => {
        const _x = align === "x" ? idx * (width + gap) : 0;
        const _y = align === "y" ? idx * (height + gap) : 0;

        return (
          <rect
            key={`app-data-loader-key-${idx}`}
            x={_x}
            y={_y}
            height={height || "100%"}
            width={width || "100%"}
            rx={rounded || "10px"}
            ry={rounded || "10px"}
            {...rectProps}
          />
        );
      })}
    </ContentLoader>
  );
};

export default DataLoader;
