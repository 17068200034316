import { useState, useEffect, useCallback } from "react";

interface WindowDimensionsData {
  width: number;
  height: number;
}

const useWindowDimensions = (): [number, number] => {
  const getWindowDimensions = useCallback(() => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }, []);

  const [windowDimensions, setWindowDimensions] = useState<WindowDimensionsData>(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [windowDimensions.width, windowDimensions.height];
};

export default useWindowDimensions;
