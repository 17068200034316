import React from "react";
import { motion, Transition, Variants, MotionStyle } from "framer-motion";

const _motionDivDefaultStyle: MotionStyle = {};

export enum MotionDivVariant {
  LeftInitial = "initial",
  LeftIn = "in",
  LeftOut = "out",

  UpInitial = "upInitial",
  UpIn = "upIn",
  UpOut = "upOut",

  DownInitial = "downInitial",
  DownIn = "downIn",
  DownOut = "downOut",

  ShowInitial = "showInitial",
  ShowIn = "showIn",
  ShowOut = "showOut",

  Show50Initial = "show50Initial",
  Show50In = "show50In",
  Show50Out = "show50Out",

  OpacityInitial = "opacityInitial",
  OpacityIn = "opacityIn",
  OpacityOut = "opacityOut",

  VisibleInitial = "visibleInitial",
  VisibleOut = "visibleOut",
}

const _motionDivDefaultVariants: Variants = {
  [MotionDivVariant.LeftInitial]: {
    opacity: 0,
    x: "-42vw",
    scale: 1,
  },
  [MotionDivVariant.LeftIn]: {
    opacity: 1,
    x: 0,
    scale: 1,
  },
  [MotionDivVariant.LeftOut]: {
    opacity: 0,
    x: "42vw",
    scale: 1,
  },

  [MotionDivVariant.UpInitial]: {
    opacity: 0,
    y: "-3.2vh",
    scale: 1,
  },
  [MotionDivVariant.UpIn]: {
    opacity: 1,
    y: 0,
    scale: 1,
  },
  [MotionDivVariant.UpOut]: {
    opacity: 0,
    y: "3.2vh",
    scale: 1,
  },
  [MotionDivVariant.DownInitial]: {
    opacity: 0,
    y: "3.2vh",
    scale: 1,
  },
  [MotionDivVariant.DownIn]: {
    opacity: 1,
    y: 0,
    scale: 1,
  },
  [MotionDivVariant.DownOut]: {
    opacity: 0,
    y: "-3.2vh",
    scale: 1,
  },

  [MotionDivVariant.ShowInitial]: {
    opacity: 0,
    scale: 0.99,
  },
  [MotionDivVariant.ShowIn]: {
    opacity: 1,
    scale: 1,
  },
  [MotionDivVariant.ShowOut]: {
    opacity: 0,
    scale: 0.95,
  },

  [MotionDivVariant.Show50Initial]: {
    opacity: 0,
    scale: 0.99,
  },
  [MotionDivVariant.Show50In]: {
    opacity: 0.5,
    scale: 1,
  },
  [MotionDivVariant.Show50Out]: {
    opacity: 0,
    scale: 0.95,
  },

  [MotionDivVariant.OpacityInitial]: {
    opacity: 0,
    scale: 1,
  },
  [MotionDivVariant.OpacityIn]: {
    opacity: 1,
    scale: 1,
  },
  [MotionDivVariant.OpacityOut]: {
    opacity: 0,
    scale: 1,
  },

  [MotionDivVariant.VisibleInitial]: {
    opacity: 1,
    scale: 1,
  },
  [MotionDivVariant.VisibleOut]: {
    opacity: 1,
    scale: 1,
  },
};

export enum MotionDivTransition {
  Default = "default",
  Average = "avg",
  Fast = "fast",
}

const _motionDivTransitionMap: Record<MotionDivTransition, Transition> = {
  [MotionDivTransition.Default]: {
    type: "tween",
    duration: 0.35,
    ease: "easeInOut",
  },
  [MotionDivTransition.Average]: {
    type: "tween",
    duration: 0.23,
    ease: "easeInOut",
  },
  [MotionDivTransition.Fast]: {
    type: "tween",
    duration: 0.15,
    ease: "easeInOut",
  },
};

interface MotionDivProps {
  children?: React.ReactNode;
  motionKey?: React.Key | null | undefined;
  className?: string | null | undefined;
  style?: MotionStyle;
  variants?: Variants;
  transition?: MotionDivTransition | Transition;
  initial?: MotionDivVariant;
  animate?: MotionDivVariant;
  exit?: MotionDivVariant;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const MotionDiv: React.VFC<MotionDivProps> = ({
  children,
  motionKey = null,
  className = "",
  style = _motionDivDefaultStyle,
  transition = MotionDivTransition.Default,
  initial = MotionDivVariant.ShowInitial,
  animate = MotionDivVariant.ShowIn,
  exit = MotionDivVariant.ShowOut,
  onClick,
}) => {
  return (
    <motion.div
      onClick={(e) => {
        onClick && onClick(e);
        return false;
      }}
      className={className ?? ""}
      key={motionKey}
      initial={initial}
      animate={animate}
      exit={exit}
      style={style}
      variants={_motionDivDefaultVariants}
      transition={
        typeof transition === "string" ? _motionDivTransitionMap[transition] : transition
      }>
      {children}
    </motion.div>
  );
};

export default MotionDiv;
