import React from "react";

import Block from "./common/Block";
import DataLoader from "./common/DataLoader";
import MotionDiv from "./common/MotionDiv";
import Spacer from "./common/Spacer";
import Text, { TextColor, TextType } from "./common/Text";

import { _randomInt } from "./shared/functions/math";
import useWindowDimensions from "./shared/hooks/useWindowDimensions";

const AppSuspenseLoader: React.VFC = () => {
  const [windowWidth] = useWindowDimensions();
  return (
    <MotionDiv>
      <Block
        isThin
        isShadow
        width={windowWidth <= 925 ? "100%" : "880px"}
        className='f-col'>
        <Text
          isExtraBold
          isCenter
          className='w-100'
          type={TextType.Middle}
          color={TextColor.Black}
          style={{ marginBottom: "10px" }}>
          Loading ...
        </Text>

        <Spacer bottom='5px' />

        <DataLoader height={64} count={_randomInt(5) + 3} gap={5} />
      </Block>
    </MotionDiv>
  );
};

export default AppSuspenseLoader;
