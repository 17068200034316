import React, { CSSProperties, useCallback, useState } from "react";

import MotionDiv from "../../../common/MotionDiv";
import Block from "../../../common/Block";
import Spacer from "../../../common/Spacer";
import LazyImage from "../../../common/LazyImage";
import ButtonIco from "../../../common/ButtonIco";
import Button, { ButtonType } from "../../../common/Button";
import Text, { TextColor, TextType } from "../../../common/Text";
import { IconColor, IconType } from "../../../common/Icon";

import useWindowDimensions from "../../../shared/hooks/useWindowDimensions";
import APIService, {
  APIDataUseCase,
  APIServicePaths,
} from "../../../services/APIService";
import ReactModal from "react-modal";
import styled from "styled-components";

interface RIAIPCButtonStyledProps {
  backgroundColor: CSSProperties["backgroundColor"];
  size?: CSSProperties["width"];
}
const RIAIPCButtonStyled = styled.div<RIAIPCButtonStyledProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};

  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 14px;
  top: 14px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: background-color 0.35s ease-in-out;

  &::hover {
    cursor: pointer;
  }
`;

interface RIAIPCWrapperProps {
  imgURL: string;
  isEditorHidden: boolean;
  onClickDelete: () => void;
  onClick: (imgURL: string) => void;
  isFullWidthPreview: boolean;
  height?: CSSProperties["height"];
  width?: CSSProperties["width"];
}

const RIAIPCWrapper: React.VFC<RIAIPCWrapperProps> = ({
  imgURL,
  isEditorHidden,
  onClickDelete,
  onClick,
  isFullWidthPreview,
  height = "380px",
}) => {
  return (
    <div
      style={{ position: "relative" }}
      className={`on-hover ${isFullWidthPreview ? "attachment-image-preview-cell" : ""}`}>
      {isEditorHidden ? null : (
        <RIAIPCButtonStyled
          backgroundColor={"rgba(0,0,0,0.5)"}
          onClick={(e) => {
            e.preventDefault();
            onClickDelete();
          }}>
          <ButtonIco
            icon={{
              type: IconType.Cross,
              size: "20px",
              color: IconColor.White,
              opacity: "100%",
            }}
            backgroundColor={"rgba(255,255,255,0.35)"}
            size='24px'
            className='on-hover'
          />
        </RIAIPCButtonStyled>
      )}

      <div
        className='attachment-image-preview'
        style={{
          height,
        }}>
        <LazyImage
          onClick={(e) => {
            e.preventDefault();
            onClick(imgURL);
          }}
          src={imgURL}
          width='100%'
          className='attachment-image-preview-img'
        />
      </div>
    </div>
  );
};

interface UseCaseApprovingProps {
  useCaseData: APIDataUseCase;
  verificationCode: string;
  onSubmit: (ucType?: "Rejected" | "Approved") => void;
}

const UseCaseApproving: React.VFC<UseCaseApprovingProps> = ({
  useCaseData,
  verificationCode,
  onSubmit,
}) => {
  const [windowWidth] = useWindowDimensions();

  const [isRequestPending, setIsRequestPending] = useState<boolean>(false);
  const onStartRequest = useCallback(() => setIsRequestPending(true), []);
  const onStopRequest = useCallback(() => setIsRequestPending(false), []);

  const [msg, setMsg] = useState<string>("");

  const onApproveUseCase = useCallback(async () => {
    onStartRequest();

    const resp = await APIService.post(APIServicePaths.UseCaseApprove, {
      code: verificationCode,
    }).then(
      (resp) => resp.data,
      () => {
        setMsg("Can't Approve Use Case, try again later...");
      }
    );

    if (resp) {
      onSubmit("Approved");
    }

    onStopRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit, verificationCode]);

  const onRejectUseCase = useCallback(async () => {
    onStartRequest();

    const resp = await APIService.post(APIServicePaths.UseCaseReject, {
      code: verificationCode,
    }).then(
      (resp) => resp.data,
      () => {
        setMsg("Can't Reject Use Case, try again later...");
      }
    );

    if (resp) {
      onSubmit("Rejected");
    }

    onStopRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit, verificationCode]);

  const [imgPreview, setImgPreview] = useState<string | undefined>(undefined);
  const onSetImgPreview = useCallback(
    (img: string) => {
      if (!imgPreview) {
        setImgPreview(img);
      }
    },
    [imgPreview]
  );
  const onClearImgPreview = useCallback(() => {
    if (imgPreview) {
      setImgPreview(undefined);
    }
  }, [imgPreview]);

  return (
    <MotionDiv>
      <ReactModal
        ariaHideApp={false}
        isOpen={!!imgPreview?.length}
        className={`app-modal auto-center app-modal-preview`}
        onRequestClose={onClearImgPreview}
        overlayClassName={`app-overlay`}>
        <RIAIPCWrapper
          isEditorHidden={false}
          isFullWidthPreview={false}
          imgURL={imgPreview as string}
          onClick={onClearImgPreview}
          onClickDelete={onClearImgPreview}
          height='100%'
          width='100%'
        />
      </ReactModal>

      <Block
        isThin
        width={windowWidth <= 925 ? "100%" : "880px"}
        className='f-col'
        style={{ overflow: "hidden" }}>
        <Text isExtraBold type={TextType.Large} color={TextColor.GreyDark}>
          {useCaseData.title}
        </Text>

        <Spacer top='36px' />

        <Text color={TextColor.GreyDark} type={TextType.Middle}>
          {useCaseData.description}
        </Text>
        <Spacer top='36px' />

        <div className='d-flex'>
          <Text isExtraBold color={TextColor.GreyDark} type={TextType.Middle}>
            Provider:
          </Text>
          <Spacer left='8px' />
          <Text color={TextColor.GreyDark} type={TextType.Middle}>
            {useCaseData.provider}
          </Text>
        </div>

        <Spacer top='12px' />
        <div className='d-flex'>
          <Text isExtraBold color={TextColor.GreyDark} type={TextType.Middle}>
            Industry focus:
          </Text>
          <Spacer left='8px' />
          <Text color={TextColor.GreyDark} type={TextType.Middle}>
            {useCaseData.industryFocus}
          </Text>
        </div>

        <Spacer top='12px' />
        <div className='d-flex'>
          <Text isExtraBold color={TextColor.GreyDark} type={TextType.Middle}>
            Function:
          </Text>
          <Spacer left='8px' />
          <Text color={TextColor.GreyDark} type={TextType.Middle}>
            {useCaseData.function}
          </Text>
        </div>

        <Spacer top='12px' />
        <div className='d-flex'>
          <Text isExtraBold color={TextColor.GreyDark} type={TextType.Middle}>
            Customer already use:
          </Text>
          <Spacer left='8px' />
          <Text color={TextColor.GreyDark} type={TextType.Middle}>
            {useCaseData.customerUsed}
          </Text>
        </div>

        <Spacer top='36px' />

        <MotionDiv
          key={"img-preview"}
          className='d-flex f-ccenter attachment-image-preview-img-grid'>
          <RIAIPCWrapper
            isEditorHidden={true}
            isFullWidthPreview={true}
            imgURL={APIService.getImageURL(useCaseData.previewPhoto.url)}
            onClick={onSetImgPreview}
            onClickDelete={() => {}}
            height='420px'
            width='100%'
          />
        </MotionDiv>

        <Spacer top='100px' />

        <div
          className='d-flex f-col'
          style={{
            padding: "24px",
            width: "calc(100% + 48px)",
            marginLeft: "-24px",
            marginBottom: "-24px",
            background: "rgba(255, 255, 255, 0.9)",
            boxShadow: "0px 0px 60px rgba(0, 0, 0, 0.1)",
            backdropFilter: "blur(20px)",
          }}>
          {msg.length ? (
            <>
              <Text className='w-100' isCenter color={TextColor.Red} isBold>
                {msg}
              </Text>

              <Spacer bottom='12px' />
            </>
          ) : null}

          <div className='d-flex f-acenter f-ccenter'>
            <Button
              disabled={isRequestPending}
              type={ButtonType.Grey}
              onClick={() => {
                onRejectUseCase();
              }}
              style={{
                padding: "12px 32px",
              }}
              className='d-flex on-hover'>
              <Text isBold color={TextColor.Grey} className='welcome-block-text'>
                Reject
              </Text>
            </Button>

            <Spacer left='24px' />

            <Button
              disabled={isRequestPending}
              type={ButtonType.Primary}
              onClick={() => {
                onApproveUseCase();
              }}
              style={{
                padding: "12px 32px",
              }}
              className='d-flex on-hover'>
              <Text
                isExtraBold
                color={TextColor.White}
                style={{
                  textShadow: "0px 1px 2px rgba(0, 0, 0, 0.35)",
                }}
                className='welcome-block-text'>
                Approve
              </Text>
            </Button>
          </div>
        </div>
      </Block>
    </MotionDiv>
  );
};

export default UseCaseApproving;
