/// SOURCE https://css-tricks.com/using-javascript-to-adjust-saturation-and-brightness-of-rgb-colors/

export interface ColorRGB {
  r: number;
  g: number;
  b: number;
}

export const HEXtoRGB = (hex: string): ColorRGB | null => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const RGBAtoString = (rgb: ColorRGB, alpha: number = 1.0): string =>
  `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`;
export const RGBtoString = (rgb: ColorRGB): string => `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
