import React, {
  CSSProperties,
  FocusEventHandler,
  ChangeEventHandler,
  HTMLInputTypeAttribute,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import styled from "styled-components";
import TextareaAutosize from "react-textarea-autosize";

import Icon, { IconType } from "../Icon";
import Spacer from "../Spacer";
import Text, { TextColor, TextProps } from "../Text";

import "./Input.scss";

interface InputDivStyledProps {
  backgroundColor: CSSProperties["backgroundColor"];
  borderRadius: CSSProperties["borderRadius"];
}

const InputDivStyled = styled.div<InputDivStyledProps>`
  display: flex;
  align-items: center;
  justify-items: center;
  border-radius: 8px;
  padding: 6px 16px;
  position: relative;

  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${({ borderRadius }) => borderRadius};
`;

type InputNullProp = undefined | null;
type InputValueProp = string | number | InputNullProp;
type InputTextProp = Exclude<InputValueProp, number>;
type InputStyleProp = CSSProperties | InputNullProp;
type InputBooleanProp = boolean | InputNullProp;

interface InputProps {
  placeholder?: InputTextProp;
  label?: React.ReactNode;
  title?: TextProps;
  titleSpaceLeft?: string;
  footnote?: InputTextProp;
  value?: InputTextProp;
  type?: HTMLInputTypeAttribute;
  onChange?: (value: string) => void | InputNullProp;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | InputNullProp;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | InputNullProp;
  leftIcon?: IconType | InputNullProp;
  rightIcon?: IconType | InputNullProp;
  style?: Omit<CSSProperties, "maxHeight" | "minHeight"> & {
    height?: number;
  };
  wrapperStyle?: InputStyleProp;
  className?: InputTextProp;
  wrapperClassName?: InputTextProp;
  disabled?: InputBooleanProp;
  required?: InputBooleanProp;
  isRounded?: InputBooleanProp;
  isTool?: InputBooleanProp;
  isTextarea?: InputBooleanProp;
  isNoBackground?: InputBooleanProp;
  minRows?: number | undefined;
}

const Input: React.VFC<InputProps> = ({
  placeholder,
  label = null,
  footnote = null,
  value = "",
  type = "text",
  onChange,
  onBlur,
  onFocus,
  leftIcon = null,
  rightIcon = null,
  style = {},
  wrapperStyle = {},
  className = "",
  wrapperClassName = "",
  disabled = false,
  required = false,
  isRounded = false,
  isTool = false,
  isTextarea = false,
  isNoBackground = false,
  minRows = 1,
  title,
  titleSpaceLeft = "8px",
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const schema = "light";
  const [_value, setValue] = useState(value);
  const onChangeValue = useCallback<
    ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  >(
    (e) => {
      e?.preventDefault();
      const _newValue = e.target.value;
      if (_newValue !== _value) {
        setValue(_newValue);
        onChange && onChange(_newValue);
      }
    },
    [_value, onChange]
  );

  const _themedProps = useMemo<
    Record<NonNullable<"light" | "dark">, Record<"wrapper", CSSProperties>>
  >(
    () => ({
      light: {
        wrapper: {
          backgroundColor: isTool ? "rgba(0, 0, 0, 0.05)" : "#fff",
        },
      },
      dark: {
        wrapper: {
          backgroundColor: isTool ? "rgba(255, 255, 255, 0.05)" : "#000",
        },
      },
    }),
    [isTool]
  );
  const _themed = useMemo(() => _themedProps[schema], [_themedProps, schema]);

  return (
    <div className='w-100'>
      {title ? (
        <>
          <div className='d-flex'>
            <Spacer left={titleSpaceLeft ?? "8px"} />
            <Text color={TextColor.GreyDark} {...title} />
          </div>
          <Spacer bottom='3px' />
        </>
      ) : null}

      <InputDivStyled
        backgroundColor={_themed.wrapper.backgroundColor}
        borderRadius={isRounded ? "100px" : "8px"}
        className={`app-common-cmp-input-wrapper ${schema} on-hover-input ${
          isTextarea ? "textaread" : ""
        } ${isNoBackground ? "noBg" : ""} ${wrapperClassName ?? ""}
        ${disabled ? "disabled" : ""}`}
        style={wrapperStyle ?? {}}
        onClick={(e) => {
          inputRef?.current?.focus();
        }}>
        {leftIcon ? (
          <>
            <Icon type={leftIcon} size='16px' opacity='35%' />
            <Spacer right='8px' />
          </>
        ) : null}

        {label && !isTextarea ? (
          <div
            className={`app-common-cmp-input-label ${
              value?.length ? "app-common-cmp-input-label-none" : ""
            }`}>
            {label}
          </div>
        ) : null}

        {isTextarea ? (
          <TextareaAutosize
            minRows={minRows}
            required={!!required}
            disabled={!!disabled}
            placeholder={placeholder ?? ""}
            value={value ?? ""}
            onBlur={(e) => (onBlur ? onBlur(e) : 0)}
            onFocus={(e) => (onFocus ? onFocus(e) : 0)}
            onChange={onChangeValue}
            className={`app-common-cmp-input textaread  ${
              label ? "labeled" : ""
            } ${schema} ${className ?? ""}`}
            style={{
              transition: "all .35s ease-in-out",
              background: "none",
              ...style,
            }}
          />
        ) : (
          <input
            ref={inputRef}
            type={type}
            required={!!required}
            disabled={!!disabled}
            placeholder={placeholder ?? ""}
            value={value ?? ""}
            onBlur={(e) => (onBlur ? onBlur(e) : 0)}
            onFocus={(e) => (onFocus ? onFocus(e) : 0)}
            onChange={onChangeValue}
            className={`app-common-cmp-input  ${label ? "labeled" : ""} ${schema} ${
              className ?? ""
            }`}
            style={{
              transition: "all .35s ease-in-out",
              background: "none",
              ...style,
            }}
          />
        )}
        {rightIcon}
      </InputDivStyled>
    </div>
  );
};

export default Input;
