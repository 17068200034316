import React, { CSSProperties } from "react";
import styled from "styled-components";

interface BlockDivProps {
  isThin: boolean;
  isShadow: boolean;
  isInvertShadow: boolean;
  isSoftShadow: boolean;
  height: CSSProperties["height"];
  minHeight: CSSProperties["minHeight"];
  width: CSSProperties["width"];
  minWidth: CSSProperties["minWidth"];
  size: CSSProperties["minWidth"];
  background: CSSProperties["background"];

  isPaused: boolean;
}
const BlockDiv = styled.div<BlockDivProps>`
  display: flex;
  transition: all 0.35s ease-in-out;
  border-radius: 12px;

  box-shadow: ${({ isShadow, isSoftShadow, isInvertShadow }) => {
    if (!isShadow) {
      return "";
    }

    const _shadow = isSoftShadow ? "0 4px 20px" : "0 10px 10px";
    const _color = isInvertShadow ? "rgba(255, 255, 255, 0.15)" : "rgba(0, 0, 0, 0.15)";

    return `${_shadow} ${_color}`;
  }};

  padding: ${({ isThin }) => (isThin ? "24px 24px" : "36px 24px")};

  height: ${({ size, height }) => size ?? height};
  min-height: ${({ size, minHeight }) => size ?? minHeight};
  width: ${({ size, width }) => size ?? width};
  min-width: ${({ size, minWidth }) => size ?? minWidth};

  background: ${({ background }) => background};
  background-size: 300% 300%;
  background-repeat: repeat;
`;

interface BlockProps extends Partial<Omit<BlockDivProps, "isPaused">> {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
  children?: React.ReactNode;
  isAccent?: boolean;
  isIgnoreSchema?: boolean;
  isInvertSchema?: boolean;
  style?: CSSProperties;
}

const Block: React.VFC<BlockProps> = ({
  onClick = () => {},
  className = "",
  children,
  isThin = false,
  isShadow = true,
  isSoftShadow = false,
  isAccent = false,
  isIgnoreSchema = false,
  isInvertSchema = false,
  isInvertShadow,
  style = {},
  height = "auto",
  minHeight = "auto",
  width = "79.203%",
  minWidth = "auto",
  background,
  size,
}) => {
  const bgGradient = `linear-gradient(106.42deg, #FFCC00, #E5BA39)`;

  let _props = { background: isAccent ? bgGradient : "#ffffff" };

  return (
    <BlockDiv
      background={background || _props.background}
      isThin={isThin}
      isShadow={isShadow}
      isSoftShadow={isSoftShadow}
      isInvertShadow={isInvertShadow ?? false}
      height={height}
      minHeight={minHeight}
      width={width}
      minWidth={minWidth}
      size={size}
      isPaused={true}
      className={`${className ? className : ""}`}
      onClick={(e) => (onClick ? onClick(e) : 0)}
      style={style ?? {}}>
      {children}
    </BlockDiv>
  );
};

export default Block;
